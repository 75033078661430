<template>
  <div>
    <transition name="fade" v-show="activeServiceSearch">
      <div id="searchServiceModal" class="search-service-component">
        <div id="searchBarBox" class="search-bar-box" tabindex="-1">
          <form action="javascript:void(0)" method="post" enctype="multipart/form-data"> <!-- class="d-flex" -->
            <div class="form-position">
              <div class="row m-0 p-0 border-radius-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0 m-0 border-radius-l-5">
                  <v-select
                      v-model="searchForm.occupation"
                      name="occupation"
                      :options="serviceTypeOccupationsOptions"
                      item-text="type"
                      item-value="id"
                      label="type"
                      placeholder="Start typing occupation name..."
                      class="select-placeholder"
                  ></v-select>
                </div>
              </div>
              <div class="row m-0 p-0 border-radius-5">
                <div class="col-8 col-sm-10 col-md-10 col-lg-10 p-0 m-0">
                  <select
                      v-model="searchForm.show_by"
                      class="search-filter-btns select-placeholder dropdown-toggle dropdown-btn text-center chevron-toggle cursor-pointer"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(searchForm.show_by)"
                      tabindex="-1"
                      aria-label="show_by"
                      name="show_by"
                      id="show_by"
                  >
                    <option value="" disabled>Show type</option>
                    <option :value="1">All</option>
                    <option :value="2">Published</option>
                    <option :value="3">Unpublished</option>
                    <option :value="4">Completed</option>
                    <option :value="5">Uncompleted</option>
                  </select>
                </div>
                <div class="col-4 col-sm-2 col-md-2 col-lg-2 p-0 m-0 text-end">
                  <router-link :to="{ name: 'addeditservice' }" class="p-0 m-0">
                    <button id="addService" class="btn btn-outline-primary btn-add" type="button">Add <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" /></button>
                  </router-link>
                </div>
              </div>
              <div class="row m-0 p-0 border-radius-5">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 p-0 m-0">
                  <div class="dropdown-container-relative">
                    <button
                        type="button"
                        id="dropdownPrice"
                        class="search-filter-btns select-placeholder dropdown-toggle dropdown-btn"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(searchForm.min_price ? searchForm.min_price : searchForm.max_price, $event)"
                    >
                      {{ searchForm.price !== 'Price' ? convertNumberToCommas(searchForm.price) : searchForm.price }}
                    </button>
                    <div class="dropdown-menu dropdown-menu-filters" aria-labelledby="dropdownPrice">
                      <div class="row m-0 p-0">
                        <div class="col-6 m-0 p-1">
                          <select
                              v-model="searchForm.min_price"
                              class="select-placeholder search-filter-btns dropdown-btn cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.min_price, $event)"
                              tabindex="-1"
                              aria-label="min_price"
                              name="min_price"
                              id="min_price"
                          >
                            <option value="" disabled>Min Price</option>
                            <option :value="0">£0</option>
                            <option :value="50">£50</option>
                            <option :value="100">£100</option>
                            <option :value="150">£150</option>
                            <option :value="200">£200</option>
                            <option :value="250">£250</option>
                            <option :value="500">£500</option>
                            <option :value="750">£750</option>
                            <option :value="1000">£1,000</option>
                            <option :value="1250">£1,250</option>
                            <option :value="1500">£1,500</option>
                            <option :value="1750">£1,750</option>
                            <option :value="2000">£2,000</option>
                            <option :value="3000">£3,000</option>
                            <option :value="4000">£4,000</option>
                            <option :value="5000">£5,000</option>
                            <option :value="10000">£10,000</option>
                            <option :value="20000">£20,000</option>
                            <option :value="30000">£30,000</option>
                            <option :value="40000">£40,000</option>
                            <option :value="50000">£50,000</option>
                            <option :value="60000">£60,000</option>
                            <option :value="70000">£70,000</option>
                            <option :value="80000">£80,000</option>
                            <option :value="90000">£90,000</option>
                            <option :value="100000">£100,000</option>
                            <option :value="110000">£110,000</option>
                            <option :value="120000">£120,000</option>
                            <option :value="130000">£130,000</option>
                            <option :value="140000">£140,000</option>
                            <option :value="150000">£150,000</option>
                            <option :value="160000">£160,000</option>
                            <option :value="170000">£170,000</option>
                            <option :value="180000">£180,000</option>
                            <option :value="190000">£190,000</option>
                            <option :value="200000">£200,000</option>
                            <option :value="210000">£210,000</option>
                            <option :value="220000">£220,000</option>
                            <option :value="230000">£230,000</option>
                            <option :value="240000">£240,000</option>
                            <option :value="250000">£250,000</option>
                            <option :value="260000">£260,000</option>
                            <option :value="270000">£270,000</option>
                            <option :value="280000">£280,000</option>
                            <option :value="290000">£290,000</option>
                            <option :value="300000">£300,000</option>
                            <option :value="310000">£310,000</option>
                            <option :value="320000">£320,000</option>
                            <option :value="330000">£330,000</option>
                            <option :value="340000">£340,000</option>
                            <option :value="350000">£350,000</option>
                            <option :value="360000">£360,000</option>
                            <option :value="370000">£370,000</option>
                            <option :value="380000">£380,000</option>
                            <option :value="390000">£390,000</option>
                            <option :value="400000">£400,000</option>
                            <option :value="410000">£410,000</option>
                            <option :value="420000">£420,000</option>
                            <option :value="430000">£430,000</option>
                            <option :value="440000">£440,000</option>
                            <option :value="450000">£450,000</option>
                            <option :value="460000">£460,000</option>
                            <option :value="470000">£470,000</option>
                            <option :value="480000">£480,000</option>
                            <option :value="490000">£490,000</option>
                            <option :value="500000">£500,000</option>
                            <option :value="550000">£550,000</option>
                            <option :value="600000">£600,000</option>
                            <option :value="650000">£650,000</option>
                            <option :value="700000">£700,000</option>
                            <option :value="750000">£750,000</option>
                            <option :value="800000">£800,000</option>
                            <option :value="850000">£850,000</option>
                            <option :value="900000">£900,000</option>
                            <option :value="950000">£950,000</option>
                            <option :value="1000000">£1,000,000</option>
                            <option :value="1250000">£1,250,000</option>
                            <option :value="1500000">£1,500,000</option>
                            <option :value="1750000">£1,750,000</option>
                            <option :value="2000000">£2,000,000</option>
                            <option :value="2250000">£2,250,000</option>
                            <option :value="2500000">£2,500,000</option>
                            <option :value="2750000">£2,750,000</option>
                            <option :value="3000000">£3,000,000</option>
                            <option :value="3250000">£3,250,000</option>
                            <option :value="3500000">£3,500,000</option>
                            <option :value="3750000">£3,750,000</option>
                            <option :value="4000000">£4,000,000</option>
                            <option :value="4250000">£4,250,000</option>
                            <option :value="4500000">£4,500,000</option>
                            <option :value="4750000">£4,750,000</option>
                            <option :value="5000000">£5,000,000</option>
                            <option :value="7500000">£7,500,000</option>
                            <option :value="10000000">£10,000,000</option>
                            <option :value="15000000">£15,000,000</option>
                            <option :value="20000000">£20,000,000</option>
                          </select>
                        </div>
                        <div class="col-6 m-0 p-1">
                          <select
                              v-model="searchForm.max_price"
                              class="select-placeholder search-filter-btns dropdown-btn cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.max_price, $event)"
                              tabindex="-1"
                              aria-label="max_price"
                              name="max_price"
                              id="max_price"
                          >
                            <option value="" disabled>Max Price</option>
                            <option :value="0">£0</option>
                            <option :value="50">£50</option>
                            <option :value="100">£100</option>
                            <option :value="150">£150</option>
                            <option :value="200">£200</option>
                            <option :value="250">£250</option>
                            <option :value="500">£500</option>
                            <option :value="750">£750</option>
                            <option :value="1000">£1,000</option>
                            <option :value="1250">£1,250</option>
                            <option :value="1500">£1,500</option>
                            <option :value="1750">£1,750</option>
                            <option :value="2000">£2,000</option>
                            <option :value="3000">£3,000</option>
                            <option :value="4000">£4,000</option>
                            <option :value="5000">£5,000</option>
                            <option :value="10000">£10,000</option>
                            <option :value="20000">£20,000</option>
                            <option :value="30000">£30,000</option>
                            <option :value="40000">£40,000</option>
                            <option :value="50000">£50,000</option>
                            <option :value="60000">£60,000</option>
                            <option :value="70000">£70,000</option>
                            <option :value="80000">£80,000</option>
                            <option :value="90000">£90,000</option>
                            <option :value="100000">£100,000</option>
                            <option :value="110000">£110,000</option>
                            <option :value="120000">£120,000</option>
                            <option :value="130000">£130,000</option>
                            <option :value="140000">£140,000</option>
                            <option :value="150000">£150,000</option>
                            <option :value="160000">£160,000</option>
                            <option :value="170000">£170,000</option>
                            <option :value="180000">£180,000</option>
                            <option :value="190000">£190,000</option>
                            <option :value="200000">£200,000</option>
                            <option :value="210000">£210,000</option>
                            <option :value="220000">£220,000</option>
                            <option :value="230000">£230,000</option>
                            <option :value="240000">£240,000</option>
                            <option :value="250000">£250,000</option>
                            <option :value="260000">£260,000</option>
                            <option :value="270000">£270,000</option>
                            <option :value="280000">£280,000</option>
                            <option :value="290000">£290,000</option>
                            <option :value="300000">£300,000</option>
                            <option :value="310000">£310,000</option>
                            <option :value="320000">£320,000</option>
                            <option :value="330000">£330,000</option>
                            <option :value="340000">£340,000</option>
                            <option :value="350000">£350,000</option>
                            <option :value="360000">£360,000</option>
                            <option :value="370000">£370,000</option>
                            <option :value="380000">£380,000</option>
                            <option :value="390000">£390,000</option>
                            <option :value="400000">£400,000</option>
                            <option :value="410000">£410,000</option>
                            <option :value="420000">£420,000</option>
                            <option :value="430000">£430,000</option>
                            <option :value="440000">£440,000</option>
                            <option :value="450000">£450,000</option>
                            <option :value="460000">£460,000</option>
                            <option :value="470000">£470,000</option>
                            <option :value="480000">£480,000</option>
                            <option :value="490000">£490,000</option>
                            <option :value="500000">£500,000</option>
                            <option :value="550000">£550,000</option>
                            <option :value="600000">£600,000</option>
                            <option :value="650000">£650,000</option>
                            <option :value="700000">£700,000</option>
                            <option :value="750000">£750,000</option>
                            <option :value="800000">£800,000</option>
                            <option :value="850000">£850,000</option>
                            <option :value="900000">£900,000</option>
                            <option :value="950000">£950,000</option>
                            <option :value="1000000">£1,000,000</option>
                            <option :value="1250000">£1,250,000</option>
                            <option :value="1500000">£1,500,000</option>
                            <option :value="1750000">£1,750,000</option>
                            <option :value="2000000">£2,000,000</option>
                            <option :value="2250000">£2,250,000</option>
                            <option :value="2500000">£2,500,000</option>
                            <option :value="2750000">£2,750,000</option>
                            <option :value="3000000">£3,000,000</option>
                            <option :value="3250000">£3,250,000</option>
                            <option :value="3500000">£3,500,000</option>
                            <option :value="3750000">£3,750,000</option>
                            <option :value="4000000">£4,000,000</option>
                            <option :value="4250000">£4,250,000</option>
                            <option :value="4500000">£4,500,000</option>
                            <option :value="4750000">£4,750,000</option>
                            <option :value="5000000">£5,000,000</option>
                            <option :value="7500000">£7,500,000</option>
                            <option :value="10000000">£10,000,000</option>
                            <option :value="15000000">£15,000,000</option>
                            <option :value="20000000">£20,000,000</option>
                          </select>
                        </div>
                        <div class="col-12 m-0 p-1">
                          <select
                              v-model="searchForm.service_payment_type_id"
                              class="select-placeholder search-filter-btns dropdown-btn cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.service_payment_type_id, $event)"
                              tabindex="-1"
                              aria-label="service_payment_type_id"
                              name="service_payment_type_id"
                              id="service_payment_type_id"
                          >
                            <option value="" disabled>Frequency</option>
                            <option :value="1">Not Applicable (N/A)</option>
                            <option :value="2">Per Hour</option>
                            <option :value="3">Per Night</option>
                            <option :value="4">Per Week</option>
                            <option :value="5">Per Month</option>
                            <option :value="6">Per Job</option>
                            <option :value="7">Per Square Meter</option>
                            <option :value="8">To Be Discussed (TBD)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-4 p-0 m-0 border-lr">
                  <select
                      v-model="searchForm.sort_by"
                      class="select-placeholder search-filter-btns dropdown-btn dropdown-btn text-center chevron-toggle cursor-pointer"
                      @focus="handleFocus($event)"
                      @focusout="handleFocusOut(searchForm.sort_by, $event)"
                      tabindex="-1"
                      aria-label="sort_by"
                      name="sort_by"
                      id="sort_by"
                  >
                    <option value="" disabled>Sort by</option>
                    <option :value="1">Newest</option>
                    <option :value="2">Oldest</option>
                    <option :value="3">Lowest Price</option>
                    <option :value="4">Highest Price</option>
                  </select>
                </div>
                <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-0 m-0 border-lr dropdown-btn text-end">
                  <button id="searchServicesFiltersButton" class="btn btn-outline-primary" v-on:click.prevent="searchServicesPortfolio" type="submit"><font-awesome-icon icon="search-location" class="icon-fa" /></button>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>
    </transition>
    <div id="searchServiceButton">
      <button id="search-service-component" :class="['btn btn-outline-primary btn-round z-index-special ', {active: activeServiceSearch}]" v-on:click="activeServiceSearch = !activeServiceSearch"><font-awesome-icon icon="search" class="ms-0" /></button>
    </div>
    <!--    <div id="searchServiceButton">-->
    <!--      <button id="search-service-component" :class="['btn btn-outline-primary btn-round z-index-special ', {active: activeServiceSearch}]" v-on:click="activeServiceSearch = !activeServiceSearch"><font-awesome-icon icon="search" class="ms-0" /></button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {mapActions} from "vuex";

export default {
  name:"ServiceSearchFilters",
  data(){
    return {
      user: this.$store.state.auth.user,
      activeServiceSearch: true,
      searchForm: {
        /* Sort By */
        sort_by: '',
        newest: false,
        oldest: false,
        highest_price: false,
        lowest_price: false,
        occupation: '',
        /* Show By */
        show_by: '',
        published: false,
        unpublished: false,
        completed: false,
        uncompleted: false,
        /* Price */
        service_payment_type_id: '',
        service_type_occupation_id: '',
        min_price: '',
        max_price: '',
        price: 'Price'
      },
      oldSearchForm: '',
      serviceTypeOccupationsOptions: [],
    }
  },
  async mounted() {
    await this.getServiceTypeOccupations();
    this.oldSearchForm = {...this.searchForm};
  },
  methods:{
    getServiceTypeOccupations: async function () {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/service-type-occupations', [], {headers: headers}).then(({data}) => {
        this.serviceTypeOccupationsOptions = data;
      })
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    searchServicesPortfolio() {
      // Perform new search
      this.$root.$emit('refreshUserServices', this.searchForm, this.$store.state.auth.user.id, 15);
      this.oldSearchForm = {...this.searchForm};
    },
    openSearchBox() {
      if(document.body.contains(document.getElementById("searchServiceModal"))) {
        document.getElementById("searchServiceModal").style.transition = "all 0.5s";
        document.getElementById("searchServiceModal").style.height = "auto";
        document.getElementById("searchServiceModal").style.visibility = "visible";
        document.getElementById("searchServiceModal").style.boxShadow = "0 0.5rem 1rem rgb(0 0 0 / 15%)";
        document.getElementById("searchServiceModal").style.opacity = "1";

        const timeout = setTimeout(
            function() {
              document.getElementById("searchServiceModal").style.overflow = "visible";
              clearTimeout(timeout);
            }, 700);
      }
      if(this.activeServiceFilters) {
        document.getElementById("searchServiceModal").style.height = "60vh";
      }
    },
    closeSearchBox() {
      if(document.body.contains(document.getElementById("searchServiceModal"))) {
        document.getElementById("searchServiceModal").style.transition = "all 0.5s";
        document.getElementById("searchServiceModal").style.visibility = "hidden";
        document.getElementById("searchServiceModal").style.border = "none";
        document.getElementById("searchServiceModal").style.boxShadow = "none";
        document.getElementById("searchServiceModal").style.opacity = "0";

        const timeout = setTimeout(
            function() {
              document.getElementById("searchServiceModal").style.overflow = "hidden";
              clearTimeout(timeout);
            }, 700);
      }
    }
  },
  created() {

  },
  watch: {
    'activeServiceSearch': function (val) {
      if (this.activeServiceSearch === true) {
        this.openSearchBox();
      } else {
        this.closeSearchBox();
      }
    },
    'searchForm': {
      handler: function (val) {
        let searchBtn = document.getElementById("searchServicesFiltersButton");
        if (searchBtn && !searchBtn.classList.contains("search-service-filter-button") && JSON.stringify(this.searchForm) !== JSON.stringify(this.oldSearchForm)) {
          searchBtn.classList.toggle("search-service-filter-button");
        }
        if (searchBtn && JSON.stringify(this.searchForm) === JSON.stringify(this.oldSearchForm)) {
          if (searchBtn.classList.contains("search-service-filter-button")) {
            searchBtn.classList.toggle("search-service-filter-button");
          }
        }
      }, deep: true
    },
    'oldSearchForm': {
      handler: function (val) {
        let searchBtn = document.getElementById("searchServicesFiltersButton");
        if (searchBtn && !searchBtn.classList.contains("search-service-filter-button") && JSON.stringify(this.searchForm) !== JSON.stringify(this.oldSearchForm)) {
          searchBtn.classList.toggle("search-service-filter-button");
        }
        if (searchBtn && JSON.stringify(this.searchForm) === JSON.stringify(this.oldSearchForm)) {
          if (searchBtn.classList.contains("search-service-filter-button")) {
            searchBtn.classList.toggle("search-service-filter-button");
          }
        }
      }, deep: true
    },
    'searchForm.min_price': function (val) {
      this.searchForm.price = '£' + val + (this.searchForm.max_price !== '' ? ' - £' + this.searchForm.max_price : '');
    },
    'searchForm.max_price': function (val) {
      this.searchForm.price = (this.searchForm.min_price !== '' ? '£' + this.searchForm.min_price : '') + ' - £' + val;
    },
    'searchForm.occupation': function (type) {
      // Set service type occupation in the form
      if (type) {
        this.searchForm.service_type_occupation_id = type.id;
      }
    },
    'searchForm.sort_by': function(val) {
      switch(val) {
        case 1:
          this.searchForm.newest = true;
          this.searchForm.oldest = false;
          this.searchForm.lowest_price = false;
          this.searchForm.highest_price = false;
          break;
        case 2:
          this.searchForm.newest = false;
          this.searchForm.oldest = true;
          this.searchForm.lowest_price = false;
          this.searchForm.highest_price = false;
          break;
        case 3:
          this.searchForm.newest = false;
          this.searchForm.oldest = false;
          this.searchForm.lowest_price = true;
          this.searchForm.highest_price = false;
          break;
        case 4:
          this.searchForm.newest = false;
          this.searchForm.oldest = false;
          this.searchForm.lowest_price = false;
          this.searchForm.highest_price = true;
          break;
      }
    },
    'searchForm.show_by': function(val) {
      switch(val) {
        case 1:
          this.searchForm.published = false;
          this.searchForm.unpublished = false;
          this.searchForm.completed = false;
          this.searchForm.uncompleted = false;
          break;
        case 2:
          this.searchForm.published = true;
          this.searchForm.unpublished = false;
          this.searchForm.completed = false;
          this.searchForm.uncompleted = false;
          break;
        case 3:
          this.searchForm.published = false;
          this.searchForm.unpublished = true;
          this.searchForm.completed = false;
          this.searchForm.uncompleted = false;
          break;
        case 4:
          this.searchForm.published = false;
          this.searchForm.unpublished = false;
          this.searchForm.completed = true;
          this.searchForm.uncompleted = false;
          break;
        case 5:
          this.searchForm.published = false;
          this.searchForm.unpublished = false;
          this.searchForm.completed = false;
          this.searchForm.uncompleted = true;
          break;
      }
    }
  }
}
</script>

<style lang="scss">

select.chevron-toggle {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: white;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='21' viewBox='0 0 24 24' width='21' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 45%;
}

/* For IE */
select.chevron-toggle::-ms-expand {
  display: none;
}

.btn-add {
  white-space: nowrap;
}

.btn-add .add-icon {
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.btn-add:hover .add-icon {
  transform: rotate(90deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem 0 0 .25rem;
  //border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}

/* Input box on focus */
.mapboxgl-ctrl-geocoder--input:focus {
  border-radius: 5px;
}

/* Search Results size */
.mapboxgl-ctrl-geocoder .suggestions li .mapboxgl-ctrl-geocoder--suggestion {
  font-size: 12px !important;
}
/* End Map Box Geocoder */

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.openServiceFilters {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px 1px 10px 10px;
  margin-top: 10px;
  font-size: 14px;
}

#filters-service-form {
  opacity: 0;
}
/* Important so that searchServicesFilters are up-front when focused */
#searchServiceModal:focus {
  z-index: 99999 !important;
}

/* Important so that searchServicesFilters are up-front when focused */
#searchServiceModal:focus-within {
  z-index: 99999 !important;
}

.dropdown-btn {
  color: #212529;
  background-color: #fff;
}

.search-service-bar * {
  //border-radius: 0;
  border: none !important;
  outline: 0;
  box-shadow: none;
}

.search-service-bar * :focus {
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.75) !important;
  outline: 0;
  box-shadow: none;
  outline: thin dotted !important;
}

.search-filter-btns {
  border-radius: 0;
  border: none;
  outline: 0;
  box-shadow: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.search-filter-btns:focus {
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.75) !important;
  outline: 0;
  box-shadow: none;
  outline: thin dotted !important;
}

/* dropdown buttons in filters */
.dropdown-container-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdown-menu {
  //position: absolute;
  //width: 220px;
  //top: 100%;
  //left: -20% !important;
}

.form-position {
  position: relative;
}

.dropdown-menu-filters {
  position: absolute;
  width: 220px;
  top: 100%;
}

.filter-select {
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  background-color: white;
  width: 100%;
  padding: 5px;
}

.dropdown-menu-prop-type {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
}

.filter-prop-label {
  background-color: #eeeeee;
  border-radius: 10px;
}

/* Start Search Service Filters Expand Filters Button - Double Chevron */
.rotate180 {
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  transform: rotate(180deg);
}

.btn-round-search-filters {
  border-radius: 0 0 50% 50% !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  border-top: none;
  border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125) ;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

#serviceFiltersButton {
  background-color: white;
  //background-color: #fcfcfc;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  position: absolute;
  bottom: -17px;
  //left: 48.3%;
  left: 48%;
  right: 48%;
  width: 30px;
  height: auto;
  //transform: translate(-50%);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}
#serviceFiltersButton:hover {
  color: red;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* End Search Service Filters Expand Filters Button - Double Chevron */

/* Search Button Animation */
.search-service-filter-button {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
/* End Search Button Animation */

.btn-round {
  border-radius: 50% !important;
}

.z-index-special {
  z-index: 9999 !important;
}

/* .btn RAF CHANGE */
#searchServiceModal .btn {
  border: 0 !important;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: none !important;
}

.border-none {
  border: none;
}

.border-lr {
  border: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.border-left {
  border: none;
  border-left: 1px solid transparent;
}

.border-right {
  border: none;
  border-right: 1px solid transparent;
}

#searchServiceButton {
  //position: absolute;
  transition: margin-left .5s;
  display: none;
}

//#searchServiceButton {
//  position: absolute;
//  transition: margin-left .5s;
//  padding: 16px;
//}

/* search bar */
.search-service-component {
  width: 100%;
  position: relative;
  z-index: 100;
  //top: 11vh;
  //left: 0;
  //transform: translate(-50%);
  background-color: rgba(255, 255, 255, 90%);
  overflow: visible;
  opacity: 1;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,.125);
  margin: 5px 0 5px 0;
}

.search-service-component a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.search-service-component a:hover {
  color: #f1f1f1;
}

.search-service-component .closebtn {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtnLeft {
  position: relative;
  top: 0;
  left: 0;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(33, 150, 243, 50%);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 0 50% 50% 0;
}

.openbtnLeft:hover {
  background-color: #444;
}

/* ===== Toggles ===== */
/* The switch - the box around the slider */
.switch {
  position: relative;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  -moz-block-height: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* End Slider */
/* ===== End Toggles ===== */

/* ===== Start Placeholders in search filters ===== */
.searchformfld{
  position: relative;
  margin: 5px 0px;
}
.searchformfld label{
  position: absolute;
  padding-left: 10px;
  top:15px;
  cursor: text;

}
.searchformfld input:focus + label,.searchformfld input:not(:placeholder-shown) + label{
  opacity:1;
  transform: scale(.9) translateY(-100%) translateX(-10px);
  color:#000;
}
.searchformfld input:focus{
  border:1px solid #000;
  outline-color: #000;
}
.searchformfld{
  padding: 15px;
  margin:15px 0px;
}
.searchformfld input{
  width:100%;
  padding-left: 10px;
}
.searchformfld label,.searchformfld input{
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity:0.5;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .search-service-component {}
  .search-service-component a {font-size: 18px;}
}
@media screen and (max-width: 575px) {
  .search-service-component .mapboxgl-ctrl-geocoder {
    border-radius: .25rem .25rem 0 0 !important;
  }
  .search-service-component .border-radius-r-5 {
    border-radius: 0 0 .25rem .25rem !important;
  }
  .search-service-component .search-filter-btns:focus {
    border-radius: 2px !important;
  }
  .border-lr {
    border: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  .border-left {
    border: none !important;
    border-left: none !important;
  }
  .border-right {
    border: none !important;
    border-right: none !important;
  }
}
@media screen and (min-width: 576px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 1920px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}

</style>
